<template>
  <div class="lp-from">
    <template v-if="qfrom.action === '是' && qfrom.listfrom.length > 0">
      <div v-for="(item, index) in qfrom.listfrom" :key="index" class="mb-10">
        <!-- input -->
        <template v-if="item.type === 'input'">
          <div class="mb-10">{{ item.name }}</div>
          <el-input v-model="item.value" placeholder="请输入" clear="mb-20" />
        </template>
        <!-- textarea -->
        <template v-if="item.type === 'textarea'">
          <div class="mb-10">{{ item.name }}</div>
          <el-input
            v-model="item.value"
            type="textarea"
            clear="mb-20"
            placeholder="请输入"
          />
        </template>
        <!-- phone -->
        <template v-if="item.type === 'phone'">
          <lp-phone
            ref="lpphone"
            :is-form="true"
            class="reset-from mb-20"
          ></lp-phone>
        </template>
        <!-- radio -->
        <template v-if="item.type === 'radio'">
          <div class="mb-10">
            {{ item.name }}
          </div>
          <el-radio-group v-model="item.value" class="mb-20">
            <el-radio
              v-for="(citem, cindex) in item.list"
              :key="index + '_radio_' + cindex"
              :label="citem.name"
            >
              {{ citem.name }}
            </el-radio>
          </el-radio-group>
        </template>
        <!-- checkbox -->
        <template v-if="item.type === 'checkbox'">
          <div class="mb-10">{{ item.name }}</div>
          <el-checkbox-group v-model="item.value" class="mb-20">
            <el-checkbox
              v-for="(citem, cindex) in item.list"
              :key="index + '_checkbox_' + cindex"
              :label="citem.name"
            >
              {{ citem.name }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </div>
      <el-button
        style="width: 100%; margin-top: 20px"
        type="primary"
        @click="submit"
      >
        提交
      </el-button>
    </template>
  </div>
</template>

<script>
import { getQueryString } from "@/uilts/uilts";
import lpPhone from "./lp-phone.vue";
import { api } from "../config/api";
import axios from "axios";
function urlParamToJson(urlParam) {
  const json = {};
  urlParam
    .trim()
    .split("&")
    .forEach((item) => (json[item.split("=")[0]] = item.split("=")[1]));

  return json;
}
export default {
  registerName: "lp-from",
  props: {
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    "lp-phone": lpPhone,
  },
  data() {
    return {
      qfrom: {
        action: "是",
        isUserInfo: "是",
        list: [],
        listfrom: [],
      },
    };
  },
  mounted() {
    if (this.componentData) {
      this.qfrom = {
        ...this.qfrom,
        ...this.componentData,
      };
    }
  },
  methods: {
    async onValidates() {
      let isAll = true;
      for (let i = 0; i < this.$refs.lpphone.length; i++) {
        const res = await this.$refs.lpphone[i].onValidate();
        if (!res) {
          isAll = false;
        }
      }
      return isAll;
    },
    async submit() {
      const isall = await this.onValidates();
      console.log(isall);
      if (isall) {
        const postJson = {};
        if (this.$refs.lpphone.length > 0) {
          const json = this.$refs.lpphone[0].onGetJson();
          postJson.phone = json.phone;
        }

        const apiurl = api + "/api/lp/data/" + getQueryString("id");
        const st = location.href.substring(
          location.href.indexOf("?") + 1,
          location.href.length
        );
        axios
          .post(apiurl, {
            query: JSON.stringify(urlParamToJson(st)),
            form: JSON.stringify({
              ...postJson,
              ...this.qfrom,
            }),
          })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("提交成功");
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$message.error("提交失败");
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lp-from {
  padding: 10px;
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
